import { Component } from "react";
import styles from "../../styles/components/basic/ConnectedWallet.module.css";
import maestro from "../../maestro";

const { blockchainManager } = maestro.managers;

class ConnectedWallet extends Component {
  state = {
    wallet: blockchainManager.store.address
  }

  componentDidMount() {
    maestro.link(this);
    this.setState({ wallet: blockchainManager.store.address });
  }

  componentWillUnmount() {
    maestro.unlink(this);
  }

  receiveStoreUpdate({ blockchain }) {
    if(blockchain && blockchain.address != this.state.wallet) {
      this.setState({ wallet: blockchain.address });
    }
  }

  render() {
    const { wallet } = this.state;
    return (
      <button className={`${styles.btn}`}>
        { wallet && ('0x.' + wallet.substring(wallet.length - 4, wallet.length)) }
      </button>
    )
  }
}

export default ConnectedWallet;