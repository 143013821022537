import { Component } from "react";
import maestro from "../maestro";
import Logo from "../components/basic/Logo";
import Header from "../components/basic/Header";
import SelectedGrayboy from "../components/SelectedGrayboy";
import FancyDescription from "../components/basic/FancyDescription";
import styles from "../styles/views/Main.module.css";
import GrayCost from "../components/GrayCost";
import MutateButton from "../components/MutateButton";
import OpenSeaButton from "../components/basic/OpenSeaButton";
import QuarantineButton from "../components/basic/QuarantineButton";
import Divider from "../components/basic/Divider";
import Bench from "../components/bench/Bench";
import FormulaStatus from "../components/bench/FormulaStatus";
import LabItemsDisplay from "../components/shop/LabItemsDisplay";
import { Outlet } from "react-router";
import Modals from "../components/modals/Modals";
import DistantTank from "../components/DistantTank";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../styles/fonts/FlyingSausage.css";
import "../styles/fonts/SuisseIntl.css";
import ConnectedWallet from "../components/basic/ConnectedWallet";
import { isMobile } from 'react-device-detect';
import Incompatible from "./Incompatible";
import GrayBoysDescriptor from "../components/basic/GrayBoysDescriptor";
import { useLocation } from 'react-router-dom';
/*
<div className={styles.outlet}>
  <Outlet/>
</div>
*/

class Main extends Component {
  state = {
    displayGBs: true
  }

  componentDidMount() {
    maestro.link(this);
  }

  componentWillUnmount() {
    maestro.unlink(this);
  }

  receiveStoreUpdate({ blockchain }) {
    if(!blockchain)
      return;

    if(blockchain.ready && (!blockchain.address || blockchain.address.length < 10)) {
      window.location.href = "../";
    }
  }

  _render() {
    const { displayGBs } = this.state;
    const { location } = this.props;
    return (
      <>
        <ToastContainer
          theme="colored"
          position="top-right"
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <div className={styles.test}>
          <div className={styles.container}>
            <div className={styles.top}>
              <Header>
                <Logo/>
                <div className={styles.topActions}>
                  <QuarantineButton uri="./quarantine"/>
                  <OpenSeaButton/>
                  <ConnectedWallet/>
                </div>
              </Header>
              <FancyDescription title="Find the formula to mutate your Gray Boy." content="Click or tap in a serum or special item to add it to the mixing chamber. Find the correct formulas, and mutate your Gray Boy NFT!"/>
            </div>
            <div className={styles.middle}>
              <DistantTank/>
            </div>
            <div className={styles.bottom}>
              <LabItemsDisplay/>
              <Header>
                <Divider/>
                <FormulaStatus extraStyles={styles.formulaStatus}/>
                <Divider/>
              </Header>
              <Bench/>
              <Divider/>
              <div className={styles.bottomDescriptors}>
                <GrayBoysDescriptor/>
                <div>COST:</div>
              </div>
              <div className={styles.bottomActions}>
                { (location.pathname == "/main/" || location.pathname == "/main") && <SelectedGrayboy/> }
                <MutateButton/>
                <GrayCost/>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.outlet}>
          <Outlet/>
        </div>
        <Modals/>
      </>
    )
  }
  render() {
    return (
      <div>
        { !isMobile && this._render() }
        { isMobile && <Incompatible/> }
      </div>
    )
  }
}

function WithLocation(props) {
  let location = useLocation();
  return <Main {...props} location={location} />
}
export default WithLocation;