import { Component } from "react";
import styles from "../../styles/components/basic/Divider.module.css";

class Divider extends Component {
  render() {
    const { extraStyles } = this.props;
    return (
      <div className={`${styles.divider} ${extraStyles}`}></div>
    )
  }
}

export default Divider;