import { Component } from "react";
import maestro from "../../maestro";
import styles from "../../styles/components/shop/LabItem.module.css";
import LabItems from "../../consts/LabItems";
import ConfirmMint from "../modals/ConfirmMint";

const { benchManager } = maestro.managers;

class LabItem extends Component {
  _mint = () => {
    const { itemId } = this.props;
    const item = LabItems.itemsMap[itemId];
    maestro.dispatchEvent('MODAL_SHOW', {
      filterStyle: item.mintStyles.filterStyle,
      modal: (
        <ConfirmMint 
          itemId={itemId}
        />
      )
    });
  }

  _addItem = () => {
    const { itemId } = this.props;
    benchManager.addIngredient(itemId);
  }

  _renderImgNormal = () => {
    const { itemId } = this.props;
    const item = LabItems.itemsMap[itemId];
    return (
      <img onClick={this._addItem} src={item.image} className={`${styles.itemImg} ${item.extraStyles}`} alt={item.name}></img>
    )
  }

  _renderImgDisabled = () => {
    const { itemId } = this.props;
    const item = LabItems.itemsMap[itemId];
    return (
      <>
        <img src={item.image} className={`${styles.itemImg} ${item.extraStyles}`} alt={item.name}></img>
        <div className={styles.disabledContainer}></div>
      </>
    )
  }

  render() {
    const { itemId, amount } = this.props;
    const item = LabItems.itemsMap[itemId];
    return (
      <div className={styles.container}>
        { amount != "0" && this._renderImgNormal() }
        { amount == "0" && this._renderImgDisabled() }
        { 
          amount != "0" && (
            <div className={styles.amountBubble}>
              <div className={styles.amount}>{amount}</div>
            </div>
          )
        }
        {item.isMintable && (<button className={styles.mintBtn} onClick={this._mint}>+ MINT</button>)}
      </div>
    )
  }
}
export default LabItem;