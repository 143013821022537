import { Component } from "react"
import maestro from '../../maestro';
import styles from "../../styles/components/modals/WalletSelection.module.css"

const { blockchainManager } = maestro.managers;

class WalletSelection extends Component {
  _connectWallet = async (connector) => {
    const { network, forceSelection, onWalletConnected } = this.props;

    try {
      await blockchainManager.requestAccounts(connector, network, forceSelection);

      if (onWalletConnected) {
        onWalletConnected();
      }
    }
    catch (error) {
      if (error.data) {
        alert(error.data.message);
      } else {
        alert(error.message);
      }
    }
  }

  componentDidMount() {
    maestro.link(this);
  }

  componentWillUnmount() {
    maestro.unlink(this);
  }

  receiveStoreUpdate({ blockchain }) {
    if (blockchain.address) {
      maestro.dispatchEvent('MODAL_HIDE', (
        <WalletSelection />
      ));
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.text}>Select your wallet provider</div>
        <button
          onClick={this._connectWallet.bind(this)}
          className={styles.btn}
        >
          MetaMask
        </button>

        <button
          onClick={this._connectWallet.bind(this, 'walletConnect')}
          className={styles.btn}
        >
          Wallet Connect
        </button>
      </div>
    );
  }
}

export default WalletSelection;