import { Component } from "react";
import gb_logo_cropped from "../../assets/images/gb_logo.svg";
import styles from "../../styles/components/basic/Logo.module.css";

class Logo extends Component {
  render() {
    const { extraStyles } = this.props;
    return (
      <>
        <img src={gb_logo_cropped} className={`${styles.logo} ${extraStyles}`}></img>
      </>
    )
  }
}

export default Logo;