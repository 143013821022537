import BenchManager from './BenchManager';
import BlockchainManager from './BlockchainManager';
import GrayboysManager from './GrayboysManager';
import InventoryManager from './InventoryManager';
import MutantsManager from './MutantsManager';
import QuarantineManager from './QuarantineManager';
import ShopManager from './ShopManager';

export default [
  BenchManager,
  BlockchainManager,
  GrayboysManager,
  MutantsManager,
  QuarantineManager,
  ShopManager,
  InventoryManager
];