import { Component } from "react";
import styles from "../styles/components/Tank.module.css";
import tank from "../assets/images/tank_masked.svg";

class Tank extends Component {
  render() {
    const { subject } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.testSubjectContainer}>
          <div className={styles.testSubjectMask}>
          <img src={subject} className={styles.testSubject}/></div>
        </div>
        <img src={tank} className={styles.tank}/>
      </div>
    )
  }
}
export default Tank;