import { Component } from "react";
import maestro from '../maestro';
import styles from "../styles/components/GrayCost.module.css";
import { useNavigate } from "react-router";
import grayTokenImg from "../assets/images/Coin.svg";
import Mutations from "../consts/Mutations";
import { BigNumber, ethers } from "ethers";

class GrayCost extends Component {
  state = {
    cost: 0
  }

  componentDidMount() {
    maestro.link(this);
  }

  componentWillUnmount() {
    maestro.unlink(this);
  }

  receiveStoreUpdate({ bench, grayboys }) {
    if(bench && bench.currentMutation != null) {
      const mutation = Mutations.formulas[bench.currentMutation];
      if(mutation.requiresGB)
        this.setState({ cost: mutation.grayTokenCost.mul(grayboys.selectedGrayboys.length).div(BigNumber.from(10).pow(18)).toString() });
      else
        this.setState({ cost: mutation.grayTokenCost.div(BigNumber.from(10).pow(18)).toString() });
    }

    if(!bench || bench.currentMutation == null)
    this.setState({ cost: "?" });
  }

  render() {
    const { cost } = this.state;
    return (
      <div className={styles.container}>
        <img className={styles.tokenImg} src={grayTokenImg}></img>
        <div className={styles.cost}>{cost}</div>
      </div>
    )
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <GrayCost {...props} navigate={navigate} />
}
export default WithNavigate;