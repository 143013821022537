import { Component } from "react";
import maestro from '../maestro';
import styles from "../styles/components/SelectedGrayboy.module.css";
import grayboy1 from "../assets/images/grayboy1.png";
import Arrow from "../assets/images/Arrow.svg";
import { useNavigate } from "react-router";

const { grayboysManager } = maestro.managers;

class SelectedGrayboy extends Component {
  _selectNew = () => {
    this.props.navigate('./select-gb');
  }

  render() {
    const { selectedGrayboys } = grayboysManager.store;
    return (
      <div className={styles.container} onClick={this._selectNew}>
        <div className={styles.imagesContainer} style={{width: `calc(8.34vh + ${Math.min(selectedGrayboys.length - 1, 2)} * 5%)`}}>
          <img className={styles.grayboyImg} src={selectedGrayboys.length > 0 ? selectedGrayboys[0].imageURL : grayboy1}></img>
          { selectedGrayboys.length > 1 && <img className={styles.grayboyImg2} src={selectedGrayboys[1].imageURL}></img> }
          { selectedGrayboys.length > 2 && <img className={styles.grayboyImg3} src={selectedGrayboys[2].imageURL}></img> }
        </div>
        { selectedGrayboys.length > 1 && (<div className={styles.grayboyId}>x{selectedGrayboys.length}</div>)}
        { selectedGrayboys.length == 1 && (<div className={styles.grayboyId}>#{selectedGrayboys[0].tokenID}</div>)}
        { selectedGrayboys.length == 0 && (<div className={styles.grayboyId}>?</div>)}
        <img className={styles.arrowImg} src={Arrow}></img>
      </div>
    )
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <SelectedGrayboy {...props} navigate={navigate} />
}
export default WithNavigate;