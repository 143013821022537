import { Manager } from 'react-native-maestro';
import LabItems from '../../consts/LabItems';

export default class InventoryManager extends Manager {
  /* Maestro required fields */
  static get instanceKey() {
    return 'inventoryManager';
  }

  static initialStore = {
    amounts: {}
  }

  get storeName() {
    return 'inventory';
  }
  /* Finish Maestro fields */

  receiveStoreUpdate({ blockchain }) {
    const { address } = this.store;
    if (blockchain && blockchain.address && address !== blockchain.address)
      this._update();
  }

  _getAmounts = async address => {
    const { blockchainManager } = this.maestro.managers;
    
    const scienceLabContract = await blockchainManager.getScienceLabContract();
    var addresses = [];
    var ids = [];
    for(const id in LabItems.itemsMap) {
      const item = LabItems.itemsMap[id];
      console.log("item:", item);
      if(!item.labId)
        continue;
      
      addresses.push(address);
      ids.push(item.labId);
    }

    const counts = await scienceLabContract.balanceOfBatch(addresses, ids);
    var i = 0;
    var ret = {};
    for(const id in LabItems.itemsMap) {
      const item = LabItems.itemsMap[id];
      if(!item.labId)
        continue;

      ret[item.labId] = counts[i] < 10 ? counts[i].toString() : "9+";
      i++;
    }
    return ret;
  }

  _update = async () => {
    const { blockchainManager } = this.maestro.managers;
    const { address } = blockchainManager.store;
    const amounts = await this._getAmounts(address);

    this.updateStore({ address: address, amounts: amounts });
  }
}
