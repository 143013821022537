import { Component } from "react";
import mutant1 from "../assets/images/mutant1.png";
import styles from "../styles/components/MutantImage.module.css";

class MutantImage extends Component {
  render() {
    const { mutant, drawBars } = this.props;
    return (
      <div className={styles.imgContainer}>
        <img className={styles.img} src={mutant.imageURL}></img>
        { drawBars && (<div className={styles.ominousBg}></div>) }
      </div>
    )
  }
}
export default MutantImage;