import { Component } from "react";
import maestro from "../../maestro";
import styles from "../../styles/components/bench/FormulaStatus.module.css";
import Mutations from "../../consts/Mutations";

const statusStyles = {
  "EMPTY": styles.emptyStatus,
  "INVALID": styles.invalidStatus,
  "VALID": styles.validStatus
}

class FormulaStatus extends Component {
  state = {
    currentStatus: "EMPTY",
    description: "EMPTY"
  }

  componentDidMount() {
    maestro.link(this);
  }

  componentWillUnmount() {
    maestro.unlink(this);
  }

  receiveStoreUpdate({ bench }) {
    const { currentStatus } = this.state;
    if(bench && bench.currentStatus != currentStatus) {
      const description = bench.currentMutation ? Mutations.formulas[bench.currentMutation].name : bench.currentStatus;
      this.setState({ currentStatus: bench.currentStatus, description: description });
    }
  }

  render() {
    const { extraStyles } = this.props;
    const { currentStatus, description } = this.state;
    return (
      <div className={`${styles.container} ${extraStyles}`}>
        <div className={styles.description}>FORMULA STATUS:</div>
        <div className={statusStyles[currentStatus]}>{description}</div>
      </div>
    )
  }
}

export default FormulaStatus;