import { Component } from "react";
import maestro from "../../maestro";

class GrayBoysDescriptor extends Component {
  state = {
    multiple: false
  }

  componentDidMount() {
    maestro.link(this);
  }

  componentWillUnmount() {
    maestro.unlink(this);
  }

  receiveStoreUpdate({ grayboys }) {
    if(grayboys) {
      this.setState({ multiple: grayboys.selectedGrayboys.length > 1 });
    }
  }

  render() {
    const { multiple } = this.state;
    return (
      <div>YOUR GRAY {multiple ? "BOYS" : "BOY"}:</div> //are you serious Matt lmao
    )
  }
}
export default GrayBoysDescriptor;