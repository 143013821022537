import { Component } from "react";
import maestro from '../maestro';
import styles from "../styles/components/MutateButton.module.css";
import ConfirmMutate from "./modals/ConfirmMutate";
import Mutations from "../consts/Mutations";

const { grayboysManager } = maestro.managers;

class MutateButton extends Component {
  state = {
    enabled: false,
    currentMutation: null,
    selectedGrayboys: [],
    requiresGB: true
  }

  componentDidMount() {
    maestro.link(this);
  }

  componentWillUnmount() {
    maestro.unlink(this);
  }

  receiveStoreUpdate({ bench, grayboys }) {
    const { selectedGrayboys } = this.state;
    if(bench) {
      if(Mutations.formulas[bench.currentMutation]) {
        var isValid = bench.currentStatus == "VALID" && (!Mutations.formulas[bench.currentMutation].requiresGB || grayboys.selectedGrayboys.length > 0);
        this.setState({ currentMutation: bench.currentMutation, enabled: isValid, requiresGB: Mutations.formulas[bench.currentMutation].requiresGB });
      }
      else
        this.setState({ currentMutation: bench.currentMutation, enabled: false });
    }

    if(grayboys && grayboys.selectedGrayboys != selectedGrayboys)
      this.setState({ selectedGrayboys: grayboys.selectedGrayboys });
  }

  _openConfirmModal = () => {
    const { currentMutation, selectedGrayboys } = this.state;
    maestro.dispatchEvent('MODAL_SHOW', {
      filterStyle: styles.modalFilterStyle,
      modal: (
        <ConfirmMutate 
          currentMutation = { currentMutation }
          grayboys = { selectedGrayboys }
        />
      )
    });
  }

  _renderEnabled() {
    const { selectedGrayboys, requiresGB } = this.state;
    return (
      <button onClick={this._openConfirmModal} className={`${styles.btn} ${styles.enabledBtn}`}>
        <div className={styles.mainText}>MUTATE</div>
        { requiresGB && <div className={styles.bottomText}>{
          selectedGrayboys.map(x => "#" + x.tokenID).join(' ')
        }</div> }
      </button>
    )
  }

  _renderDisabled() {
    const { selectedGrayboy } = this.state;
    return (
      <button className={`${styles.btn} ${styles.disabledBtn}`}>
        <div className={styles.mainText}>MUTATION DISABLED</div>
        <div className={styles.bottomText}>
          { selectedGrayboy ? "FILL THE FORMULA TO MUTATE" : "SELECT A GRAY BOY TO CONTINUE" }
        </div>
      </button>
    )
  }

  render() {
    const { enabled } = this.state;
    return (
      <>
        {enabled && this._renderEnabled()}
        {!enabled && this._renderDisabled()}
      </>
    )
  }
}

export default MutateButton;