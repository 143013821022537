import { Component } from "react";
import maestro from "../../maestro";
import styles from "../../styles/components/modals/ConfirmMint.module.css";
import grayToken from "../../assets/images/Coin.svg";
import { toast } from 'react-toastify';
import { serializeError } from 'eth-rpc-errors';
import LabItems from "../../consts/LabItems";

const { shopManager } = maestro.managers;

class ConfirmMint extends Component {
  state = {
    amount: 1
  }

  _update = (offset) => {
    const { itemId } = this.props;
    const { amount } = this.state;
    const item = LabItems.itemsMap[itemId];

    if(amount + offset < 1 || amount + offset > item.maxMintsPerTransaction)
      return;

    this.setState({ amount: amount + offset });
  }

  _mint = async () => {
    const { itemId } = this.props;
    const { amount } = this.state;
    const item = LabItems.itemsMap[itemId];
    try {
      const tx = await shopManager.mintItem(itemId, amount);
      this._close();
      toast.promise(tx.wait(), {
        pending: `Minting ${amount}x ${item.name}`,
        success: `${amount}x ${item.name} minted`,
        error: 'Unexpected error'
     })
    }
    catch (error) {
      var parsedError = serializeError(error);
      toast.error(parsedError.data.originalError.reason, {
        position: "top-center",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  _close = () => {
    maestro.dispatchEvent('MODAL_HIDE');
  }

  render() {
    const { itemId } = this.props;
    const { amount } = this.state;
    
    const item = LabItems.itemsMap[itemId];
    return (
      <div className={`${styles.container} ${item.mintStyles.container}`}>
        <div className={styles.centerContainer}>
          <img className={styles.tokenImg} src={item.image}></img>
        </div>
        <div className={styles.areYouSure}>How many more {item.name} would you like to mint?</div>
        <div className={styles.counter}>
          <button className={`${styles.counterButton} ${item.mintStyles.counterButton}`} onClick={() => this._update(-1)}>
            <div className={styles.minusButton}></div>
          </button>
          <div className={styles.counterText}>{amount}</div>
          <button className={`${styles.counterButton} ${item.mintStyles.counterButton}`} onClick={() => this._update(+1)}>+</button>
        </div>
        <button className={`${styles.confirmButton} ${item.mintStyles.confirmButton}`} onClick={this._mint}>MINT</button>
        <div className={styles.centerContainer}>
          <button className={`${styles.cancelButton} ${item.mintStyles.cancelButton}`} onClick={this._close}>CANCEL</button>
        </div>
      </div>
    )
  }
}

export default ConfirmMint;