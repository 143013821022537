import { Component } from "react";
import { useNavigate } from "react-router";
import maestro from "../maestro";
import styles from "../styles/components/MutantsDisplay.module.css";
import Mutant from "./Mutant";

const { mutantsManager } = maestro.managers;

class MutantsDisplay extends Component {
  state = {
    mutants: []
  }

  componentDidMount() {
    maestro.link(this);
    this._update();
  }

  componentWillUnmount() {
    maestro.unlink(this);
  }

  receiveEvent(name, data) {
    const { mutants } = this.state;
    if(name == "MUTANT_QUARANTINED" || name == "MUTANT_UNQUARANTINED")
      this.setState({ mutants: mutants }); //force redraw
  }

  receiveStoreUpdate({ mutants }) {
    if(mutants.owned) {
      this.setState({ mutants: mutants.owned });
    }
  }

  _update() {
    const { owned } = mutantsManager.store;
    this.setState({ mutants: owned });
  }

  //redirect to opensea
  _onClick = (tokenID) => {
    /*mutantsManager.selectGrayboy(tokenID);
    this.props.navigate('../');*/
  }

  render() {
    const { mutants } = this.state;
    return (
      <div className={styles.container}>
        { 
          mutants.filter(x => x.isQuarantined).map((mutant) => (
            <div 
              className={styles.containerItem}
              key = {`mutant_container_${ mutant.tokenID }`}
            >
              <Mutant
                onClick = {() => this._onClick(mutant.tokenID)}
                isQuarantined = { mutant.isQuarantined }
                tokenId = { mutant.tokenID }
                key = {`mutant_${ mutant.tokenID }`}
                quarantineTime = { mutant.quarantineTime }
                mutant = { mutant }
              />
            </div>
          ))
        }
        {
          mutants.filter(x => !x.isQuarantined).map((mutant, index) => (
            <div 
              className={styles.containerItem}
              key = {`mutant_container_${ mutant.tokenID }`}
            >
              <Mutant
                onClick = {() => this._onClick(mutant.tokenID)}
                isQuarantined = { mutant.isQuarantined }
                tokenId = { mutant.tokenID }
                key = {`mutant_${ mutant.tokenID }`}
                quarantineTime = { mutant.quarantineTime }
                mutant = { mutant }
              />
            </div>
          ))
        }
      </div>
    )
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <MutantsDisplay {...props} navigate={navigate} />
}
export default WithNavigate;