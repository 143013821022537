import { Component } from "react";
import maestro from "../maestro";
import styles from "../styles/components/BigConnectButton.module.css";
import WalletSelection from "./modals/WalletSelection";
import "../styles/fonts/SuisseIntl.css";

class BigConnectButton extends Component {
  _openWalletSelectionModal = () => {
    maestro.dispatchEvent('MODAL_SHOW', (
      <WalletSelection />
    ));
  }

  render() {
    return (
      <button className={styles.btn} onClick={this._openWalletSelectionModal}>
        <div className={styles.mainText}>CONNECT WALLET</div>
        <div className={styles.bottomText}>TO BEGIN</div>
      </button>
    )
  }
}

export default BigConnectButton;