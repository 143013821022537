import { Manager } from 'react-native-maestro';
import detectEthereumProvider from '@metamask/detect-provider';
import WalletConnectProvider from '@walletconnect/web3-provider';
import grayTokenABI from "../../assets/abi/GrayToken.json";
import quarantineABI from "../../assets/abi/Quarantine.json";
import scienceLabABI from "../../assets/abi/ScienceLab.json";
import failedExperimentsABI from "../../assets/abi/FailedExperiments.json";
import mutatorABI from "../../assets/abi/Mutator.json";
import Contracts from '../../consts/Contracts';
import { toast } from 'react-toastify';
import { serializeError } from 'eth-rpc-errors';
import { ethers } from 'ethers';

const CHAINS = {
  homestead: { // Ethereum
    name: 'Ethereum Mainnet',
    chainId: 1,
    chainIdHex: '0x1',
    rpc: 'https://eth-mainnet.alchemyapi.io/v2/CB_GX9yDv6FDfe5e3keFLQesHbZPGpCg',
    key: 'CB_GX9yDv6FDfe5e3keFLQesHbZPGpCg',
  },
  polygon: { // Polygon
    name: 'Polygon Mainnet',
    chainId: 137,
    chainIdHex: '0x89',
    rpc: 'https://polygon-rpc.com',
    key: null
  }
};

export default class BlockchainManager extends Manager {
  provider = null;
  contractInstances = {};

  /* Maestro required fields */
  static get instanceKey() {
    return 'blockchainManager';
  }

  static initialStore = {
    address: null,
    network: null,
    ready: false,
  }

  get storeName() {
    return 'blockchain';
  }

  constructor(maestro) {
    super(maestro);

    this.getAndSetProvider().then(async () => {
      this.setEthereumOnHandler('accountsChanged', () => {
        this._syncStoreWithProvider();
      });

      this.setEthereumOnHandler('chainChanged', () => {
        this._syncStoreWithProvider();
      });
    }).finally(() => {
      this._syncStoreWithProvider();
    });
  }
  /* Finish Maestro fields */

  /* Contracts */
  getGrayTokenContract(network = 'homestead', readOnly = false) {
    const contractAddress = Contracts.GRAY_TOKEN_CONTRACT_ADDRESS;
    return this.getContractInstance(contractAddress, grayTokenABI, readOnly, network);
  }

  getQuarantineContract(network = 'homestead', readOnly = false) {
    const contractAddress = Contracts.QUARANTINE_CONTRACT_ADDRESS;
    return this.getContractInstance(contractAddress, quarantineABI, readOnly, network);
  }

  getFailedExperimentsContract(network = 'homestead', readOnly = false) {
    const contractAddress = Contracts.FAILED_EXPERIMENTS_CONTRACT_ADDRESS;
    return this.getContractInstance(contractAddress, failedExperimentsABI, readOnly, network);
  }

  getMutatorContract(network = 'homestead', readOnly = false) {
    const contractAddress = Contracts.MUTATOR_CONTRACT_ADDRESS;
    return this.getContractInstance(contractAddress, mutatorABI, readOnly, network);
  }

  getScienceLabContract(network = 'homestead', readOnly = false) {
    const contractAddress = Contracts.SCIENCE_LAB_CONTRACT_ADDRESS;
    return this.getContractInstance(contractAddress, scienceLabABI, readOnly, network);
  }

  getContractInstance(contractAddress, abi, readOnly = false, network = '') {
    const signerProvider = (!readOnly) ? this.getSigner() : this.getReadOnlyProvider(network);

    if (!contractAddress || !signerProvider) {
      console.log('no contract address or signer, provider likely not set');
      return false;
    }

    const instanceHash = `${contractAddress}${signerProvider.address}${readOnly}${network}`;

    this.contractInstances[instanceHash] =
      this.contractInstances[instanceHash] ||
      new ethers.Contract(contractAddress, abi, signerProvider);

    return this.contractInstances[instanceHash];
  }
  /* Finish Contracts */

  async _syncStoreWithProvider() {
    let address = null;

    this.updateStore({ ready: false });

    try {
      address = await this.getSignerAddress();
    }
    catch (error) {
      console.log('Store Sync Error, Possibly No Provider?');
      console.log(error);
    }

    this.updateStore({
      address,
      network: await this.getNetworkName(),
      ready: true,
    });
  }

  async getAndSetProvider() {
    const providerProxy = await detectEthereumProvider();

    if (providerProxy) {
      this.provider = new ethers.providers.Web3Provider(providerProxy, 'any');
      console.log(this.provider);
    }

    return this.provider;
  }

  async getSignerAddress() {
    const signer = this.getSigner();

    if (!signer) {
      console.log('provider not set, no signer.');
      return false;
    }

    return signer.getAddress();
  }

  getSigner() {
    if (!this.provider) {
      console.log('provider not set.');
      return false;
    }

    return this.provider.getSigner();
  }

  async requestAccounts(connector, networkName = 'homestead', force = false) {
    if (connector && connector === 'walletConnect') {
      const walletConnectProvider = new WalletConnectProvider({
        rpc: {
          [CHAINS.homestead.chainId]: CHAINS.homestead.rpc, // homestead
        },
        chainId: CHAINS[networkName].chainId,
        qrcode: true,
      });
      await walletConnectProvider.enable();
      this.provider = new ethers.providers.Web3Provider(walletConnectProvider);
      this._syncStoreWithProvider();
      return this.provider;
    }

    if (!this.provider) {
      console.log('provider not set');
      return;
    }

    if (!force) {
      return this.provider.send('eth_requestAccounts', []);
    } else {
      try {
        return await this.provider.send('wallet_requestPermissions', [
          { eth_accounts: {} },
        ]);
      } catch (error) {
        return await this.provider.send('eth_requestAccounts', []);
      }
    }
  }

  async getNetworkChainId() {
    if (!this.provider) {
      console.log('provider not set.');
      return false;
    }

    const networkName = await this.getNetworkName();

    return CHAINS[networkName].chainId;
  }

  async getNetworkName() {
    if (!this.provider) {
      console.log('provider not set.');
      return false;
    }

    return (await this.provider.getNetwork()).name;
  }

  async switchWalletToEthereumNetwork() {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [ { chainId: CHAINS.homestead.chainIdHex } ],
      });
    } catch (error) {
      var parsedError = serializeError(error);
      toast.error(parsedError.message, {
        position: "top-center",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  setEthereumOnHandler(event, callback) {
    if (!window.ethereum) {
      console.log('could not find ethereum object.');
      return false;
    }

    window.ethereum.on(event, callback);
  }
}