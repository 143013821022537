import { Manager } from 'react-native-maestro';

export default class QuarantineManager extends Manager {
  /* Maestro required fields */
  static get instanceKey() {
    return 'quarantineManager';
  }

  static initialStore = {
    owned: []
  }

  get storeName() {
    return 'quarantine';
  }
  /* Finish Maestro fields */

  receiveStoreUpdate({ blockchain }) {
    const { address } = this.store;
    if (blockchain && blockchain.address && address !== blockchain.address) {
      this.updateStore({ address: blockchain.address });
      this._update();
    }
  }

  claimRewards = async () => {
    const { address } = this.store;
    const { blockchainManager } = this.maestro.managers;
    
    const quarantineContract = await blockchainManager.getQuarantineContract();
    return await quarantineContract.claim(address);
  }

  quarantine = async (collectionID, mutants) => {
    const { address } = this.store;
    const { blockchainManager } = this.maestro.managers;
    const quarantineContract = await blockchainManager.getQuarantineContract();
    var tokenIDs = [];
    var weights = [];

    mutants.forEach(mutant => {
      tokenIDs.push(mutant.tokenID);
      weights.push(mutant.weight);
    });

    const signatureResponse = await this._getSignature(collectionID, tokenIDs);
    return await quarantineContract.initialQuarantine(collectionID, tokenIDs, weights, address, signatureResponse.signature);
  }

  unquarantine = async (collectionID, mutants) => {
    const { address } = this.store;
    const { blockchainManager } = this.maestro.managers;
    const quarantineContract = await blockchainManager.getQuarantineContract();

    var tokenIDs = [];
    mutants.forEach(mutant => tokenIDs.push(mutant.tokenID));
    return await quarantineContract.release(collectionID, tokenIDs, address);
  }

  _getSignature = async (collectionID, tokenIDs) => {
    const { apiHelper } = this.maestro.helpers;
    const result = await apiHelper.post({
      data: JSON.stringify({
        TokenIDs: tokenIDs
      }),
      path: `/signatures/${collectionID}`
    });
    return result.body;
  }

  _getMutants = async address => {
    const { apiHelper } = this.maestro.helpers;
    const stakedWorldsResult = await apiHelper.get({
      path: `/mutants/owned/${address}`,
    });
    return stakedWorldsResult.body;
  }

  _update = async () => {
    const { blockchainManager } = this.maestro.managers;
    const { address } = blockchainManager.store;
    var mutants = await this._getMutants(address);
    this.updateStore({ address: address, owned: mutants });
  }
}
