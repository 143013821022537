import { Component } from "react";
import styles from "../styles/components/ViewOnOpenSea.module.css";

class ViewOnOpenSeaButton extends Component {
  _redirectToOpenSea = () => {
    const { tokenId } = this.props;
    window.open("https://opensea.io/assets/ethereum/0xf4539901ea3f319EF92888413796B59b26ae4C4a/" + tokenId, "_blank");
  }

  render() {
    return (
      <button className={styles.openseaBtn} onClick={this._redirectToOpenSea}>
        VIEW ON OPENSEA
      </button>
    )
  }
}

export default ViewOnOpenSeaButton;