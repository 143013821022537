import { Component } from "react";
import maestro from "../maestro";
import { useNavigate } from "react-router";
import styles from "../styles/views/GrayboySelection.module.css";
import FancyDescription from "../components/basic/FancyDescription";
import GrayboysDisplay from "../components/GrayboysDisplay";
import Header from "../components/basic/Header";
import X from "../assets/images/X.svg";

const { grayboysManager } = maestro.managers;

class GrayboySelection extends Component {
  state = {
    grayboys: []
  }

  componentDidMount() {
    maestro.link(this);
    this._update();
  }

  componentWillUnmount() {
    maestro.unlink(this);
  }

  receiveStoreUpdate({ grayboys }) {
    if(grayboys.owned && grayboys.owned != this.state.grayboys) {
      this.setState({ grayboys: grayboys.owned });
    }
  }

  _update() {
    const { owned } = grayboysManager.store;
    this.setState({ grayboys: owned });
  }

  _close = () => {
    this.props.navigate('../');
  }
  
  render() {
    const { grayboys } = this.state;
    return (
      <div className={ styles.container }>
        <Header>
          <FancyDescription 
            title="Select an NFT to mutate:"
            content="You can mutate each Gray Boy once per formula."
          />
          <img className={styles.removeImg} src={X} onClick={this._close}></img>
        </Header>
        <div style={{marginTop:"2vh"}}></div>
        <GrayboysDisplay grayboys={grayboys}/>
      </div>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <GrayboySelection {...props} navigate={navigate} />
}
export default WithNavigate;