/*class Contracts {
  static GRAY_TOKEN_CONTRACT_ADDRESS = "0xa7eDe417F7E69Aa92E510471aAAa5a0B015D06D9";
  static GRAYBOYS_CONTRACT_ADDRESS = "0x55453C2aE3098a692b7b8a722f140E99dE7199D8";
  static QUARANTINE_CONTRACT_ADDRESS = "0xEd487Ae804B28712e75335548b36f09E6136ac5d";
  static SCIENCE_LAB_CONTRACT_ADDRESS = "0xD0782A0797aFBb1D46519075DAad05DF9b04c502";
  static FAILED_EXPERIMENTS_CONTRACT_ADDRESS = "0xEAcD276A8c8441d7ED7394FbFae6ba642690992C";
  static MUTATOR_CONTRACT_ADDRESS = "0x9feD372e75F3C5BcfB06033D03981151bF20595C";
}
export default Contracts;*/

class Contracts {
  static GRAY_TOKEN_CONTRACT_ADDRESS = "0xa7eDe417F7E69Aa92E510471aAAa5a0B015D06D9";
  static GRAYBOYS_CONTRACT_ADDRESS = "0x8d4100897447d173289560BC85c5C432Be4f44E4";
  static QUARANTINE_CONTRACT_ADDRESS = "0xEd487Ae804B28712e75335548b36f09E6136ac5d";
  static SCIENCE_LAB_CONTRACT_ADDRESS = "0xF90733aB2F368ffe41BFBA80443E04fc33321f67";
  static FAILED_EXPERIMENTS_CONTRACT_ADDRESS = "0xf4539901ea3f319EF92888413796B59b26ae4C4a";
  static MUTATOR_CONTRACT_ADDRESS = "0x14D5908F28f0b38E4D8F67d2187936a82d15a81E";
}
export default Contracts;