import { Component } from "react";
import maestro from "../maestro";
import Header from "./basic/Header";
import DaysInQuarantine from "./DaysInQuarantine";
import ConfirmClaim from "./modals/ConfirmClaim";
import styles from "../styles/components/Mutant.module.css";
import ToggleQuarantineButton from "./ToggleQuarantineButton";
import MutantImage from "./MutantImage";

class Mutant extends Component {
  _openClaimModal = () => {
    maestro.dispatchEvent('MODAL_SHOW', {
      filterStyle: styles.modalFilterStyle,
      modal: (<ConfirmClaim/>)
    });
  }

  render() {
    const { onClick, mutant } = this.props;
    return (
      <div className={styles.container} onClick={onClick}>
        <MutantImage drawBars={mutant.isQuarantined} mutant={mutant}/>
        <div className={styles.bottom}>
          <Header>
            <DaysInQuarantine days={mutant.quarantineTime}/>
            { mutant.isQuarantined && (<button className={styles.btn} onClick={this._openClaimModal}>CLAIM</button>) }
          </Header>
          <ToggleQuarantineButton
            mutant = { mutant }
          />
        </div>
      </div>
    )
  }
}

export default Mutant;