import { Manager } from "react-native-maestro";
import Mutations from "../../consts/Mutations";

class BenchManager extends Manager {
  /* Maestro required fields */
  static get instanceKey() {
    return 'benchManager';
  }

  static initialStore = {
    ingredients: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    currentStatus: "EMPTY",
    currentMutation: null
  }

  get storeName() {
    return 'bench';
  }
  /* Finish Maestro fields */

  _groupIngredients(ingredients) {
    const result = {};

    for(const ingredient of ingredients) {
      if(!result[ingredient]) result[ingredient] = 0;
      result[ingredient]++;
    }
    return result;
  }

  _isMutation(id, grouped) {
    if(Object.keys(grouped).length - 1 !== Object.keys(Mutations.formulas[id].costs).length) return false;
    for(const ingredient in grouped) {
      if(ingredient == 0) continue;
      if(ingredient && grouped[ingredient] !== Mutations.formulas[id].costs[ingredient])
        return false;
    }
    return true;
  }

  _updateMutation(ingredients) {
    var grouped = this._groupIngredients(ingredients);

    if(grouped[0] === ingredients.length) {
      this.updateStore({ currentMutation: null, currentStatus: "EMPTY" });
      return;
    }

    for(const id in Mutations.formulas) {
      if(!this._isMutation(id, grouped))
        continue;
      
      this.updateStore({ currentMutation: id, currentStatus: "VALID" });
      return;
    }

    this.updateStore({ currentMutation: null, currentStatus: "INVALID" });
  }

  addIngredient(id) {
    const { ingredients } = this.store;
    for(var i = 0; i < ingredients.length; i++) {
      if(ingredients[i] !== 0)
        continue;
      
      ingredients[i] = id;
      break;
    }
    
    this._updateMutation(ingredients);
    this.updateStore({ ingredients: ingredients });
  }

  removeAt(slotId) {
    const { ingredients } = this.store;
    ingredients[slotId] = 0;

    this._updateMutation(ingredients);
    this.updateStore({ ingredients: ingredients });
  }
}
export default BenchManager;