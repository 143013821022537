import { Helper } from 'react-native-maestro';

export default class MutatorHelper extends Helper {
  static get instanceKey() {
    return 'mutatorHelper';
  }

  async mutateL1(grayboys) {
    const { blockchainManager } = this.maestro.managers;
    const mutatorContract = await blockchainManager.getMutatorContract();
    return await mutatorContract.mutateL1(grayboys.map(x => x.tokenID));
  }

  async mutateL2(grayboys) {
    const { blockchainManager } = this.maestro.managers;
    const mutatorContract = await blockchainManager.getMutatorContract();
    return await mutatorContract.mutateL2(grayboys.map(x => x.tokenID));
  }

  async mutateCrystal(count) {
    const { blockchainManager } = this.maestro.managers;
    const mutatorContract = await blockchainManager.getMutatorContract();
    return await mutatorContract.mutateCrystal(count);
  }

  async mutateEmbryo(count) {
    const { blockchainManager } = this.maestro.managers;
    const mutatorContract = await blockchainManager.getMutatorContract();
    return await mutatorContract.mutateEmbryo(count);
  }
}
