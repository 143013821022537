import { Component } from "react";
import maestro from "../../maestro";
import styles from "../../styles/components/bench/IngredientSpot.module.css";
import X from "../../assets/images/X.svg";
import LabItems from "../../consts/LabItems";

const { benchManager } = maestro.managers;

class IngredientSpot extends Component {
  _remove = () => {
    const { slotId } = this.props;
    benchManager.removeAt(slotId);
  }

  render() {
    const { slotId, ingredientId, amount } = this.props;
    return (
      <div className={styles.container} onClick={this._remove}>
        <img className={`${styles.formulaImg} ${LabItems.itemsMap[ingredientId].extraStyles}`} src={LabItems.itemsMap[ingredientId].image}></img>
        { ingredientId !== 0 && amount > 1 && (<div className={styles.itemCount} src={X}><div className={styles.amount}>{amount}x</div></div>) }
        { ingredientId !== 0 && (<img className={styles.removeImg} src={X}></img>) }
        { ingredientId == 0 && (<div className={styles.number}>{slotId + 1}</div>) }
      </div>
    )
  }
}

export default IngredientSpot;