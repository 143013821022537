import { Component } from "react";
import styles from "../../styles/components/basic/OpenSeaButton.module.css";
import opensea_icon from "../../assets/images/opensea_icon.png"

class OpenSeaButton extends Component {
  _redirectToOpenSea = () => {
    window.location.href = "https://opensea.io/collection/gray-boys-mutants";
  }

  render() {
    return (
      <button className={styles.btn} onClick={this._redirectToOpenSea}>
        <img src={opensea_icon} alt="opensea"></img>
      </button>
    )
  }
}

export default OpenSeaButton;