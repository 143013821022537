import { Component } from "react";
import { useNavigate } from 'react-router-dom';
import left_cropped from "../../assets/images/left_cropped.svg";
import styles from "../../styles/components/basic/BackToLabButton.module.css";

class BackToLabButton extends Component {
  render() {
    return (
      <button onClick={() => this.props.navigate('../')} className={styles.btn}>
        <img src={left_cropped}></img>
        <div className={styles.text}>BACK TO LAB</div>
      </button>
    )
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <BackToLabButton {...props} navigate={navigate} />
}
export default WithNavigate;