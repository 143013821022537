import { Component } from "react";
import styles from "../../styles/components/basic/QuarantineButton.module.css";
import quarantine_icon from "../../assets/images/quarantine_icon.svg"
import { useNavigate } from "react-router";

class QuarantineButton extends Component {
  _redirect = () => {
    const { uri } = this.props;
    this.props.navigate(uri);
  }

  render() {
    return (
      <button className={styles.btn} onClick={this._redirect}>
        <img src={quarantine_icon} alt="quarantine"></img>
        QUARANTINE
      </button>
    )
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <QuarantineButton {...props} navigate={navigate} />
}
export default WithNavigate;