import { Component } from "react";
import maestro from "../maestro";
import "../styles/animations/ThreeDots.css";
import styles from "../styles/components/Grayboy.module.css";
import mutated from "../assets/images/mutated.svg";

const { grayboysManager } = maestro.managers;

class Grayboy extends Component {
  state = {
    isLoading: true
  }

  _onImageLoad = () => {
    this.setState({isLoading: false});
  }

  _click = () => {
    const { grayboy } = this.props;
    grayboysManager.toggleGrayboy(grayboy);
    this.setState({});
  }

  render() {
    const { isLoading } = this.state;
    const { grayboy, notSelectable, spacer } = this.props;
    const isMutated = grayboy.mutations.length > 0;
    return (
      <div className={styles.grayboy} onClick={notSelectable ? null : this._click} style={{opacity: spacer ? "0" : null, pointerEvents: spacer ? "none" : null}}>
        <div className={styles.grayboyImgContainer}>
          { isLoading && (
            <div className={styles.loadLayer}>
              <div className="dot-spin"></div>
            </div>
          )}
          { isMutated && <div className={styles.blackLayer}></div> }
          { isMutated && <img src={mutated} className={styles.mutatedText}></img> }
          <img className={`${styles.grayboyImg} ${grayboy.isSelected ? styles.greenBorder : ""}`} src={grayboy.imageURL} onLoad={this._onImageLoad}></img>
          <div className={styles.mutationLayer}>
            {
              isMutated &&
              grayboy.mutations.map(mutation => (
                <div className={styles.mutationIcon}>
                  <div className={styles.mutationSymbol}>
                  L{mutation.typeID + 1}
                  </div>
                </div>
              ))
            }
          </div>
        </div>
        <div className={styles.grayboyNumber}>#{grayboy.tokenID}</div>
        { grayboy.isSelected && <div className={styles.ominousBg}></div> }
      </div>
    )
  }
}

export default Grayboy;