import { Component } from "react";
import styles from "../../styles/components/modals/Modals.module.css";
import maestro from '../../maestro';
import X from "../../assets/images/X.svg";

class Modals extends Component {
  state = {
    modalContent: null,
    visible: false,
    disableClose: false
  }

  _showModal = async (modalContent) => {
    this.setState({
      modalContent,
      visible: true,
    });
  }

  _hideModal = async () => {
    this.setState({
      visible: false,
    });
  }

  componentDidMount() {
    maestro.link(this);
  }

  componentWillUnmount() {
    maestro.unlink(this);
  }

  receiveEvent(name, data) {
    if (name === 'MODAL_SHOW') {
      this.setState({ filterStyle: data.filterStyle, disableClose: data.disableClose });
      this._showModal(data.modal ? data.modal : data);
    }

    if (name === 'MODAL_HIDE') {
      this._hideModal();
    }
  }

  render() {
    const { disableClose, modalContent, visible, filterStyle } = this.state;

    if (!visible) {
      return null;
    }

    return (
      <div className={styles.container}>
        <div className={styles.overlay} onClick={!disableClose ? this._hideModal : null}></div>
        <div className={`${styles.filter} ${filterStyle}`} onClick={!disableClose ? this._hideModal : null}></div>
        <div className={styles.modalContent}>
          { !disableClose && <img className={styles.removeImg} src={X} onClick={this._hideModal}></img> }
          {modalContent}
        </div>
      </div>
    );
  }
}

export default Modals;