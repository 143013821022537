import { Helper } from 'react-native-maestro';

/* Shamelessly stolen from Ark's World Manager code. */
const productionEnvironment = {
  host: 'https://lab-api.grayboysnft.com',
};

const developmentEnvironment = {
  host: 'https://lab-api.grayboysnft.com',
};

export default class ApiHelper extends Helper {
  static get instanceKey() {
    return 'apiHelper';
  }

  get(options) {
    return this._request({ ...options, method: 'GET' });
  }

  put(options) {
    return this._request({ ...options, method: 'PUT' });
  }

  post(options) {
    return this._request({ ...options, method: 'POST' });
  }

  patch(options) {
    return this._request({ ...options, method: 'PATCH' });
  }

  delete(options) {
    return this._request({ ...options, method: 'DELETE' });
  }

  async uploadFile(options) {
    const formData = new FormData();

    if (options.file) {
      formData.append('uri', options.fileUri);
      formData.append(options.fileName, options.file);
    }

    if (options.files) {
      Object.keys(options.files).forEach(fileName => {
        console.log(fileName);
        console.log(options.files[fileName]);
        formData.append(fileName, options.files[fileName]);
      });
    }

    if (options.data) {
      Object.keys(options.data).forEach(key => {
        if (options.data[key] !== undefined) {
          formData.append(key, options.data[key]);
        }
      });
    }

    return this._request({
      ...options,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...options.headers,
      },
    });
  }

  _getEnvironment() {
    const { host } = window.location;

    if (host.includes('localhost')) {
      return developmentEnvironment;
    }

    return productionEnvironment;
  }

  async _request(options) {
    // const { userManager } = this.maestro.managers;
    const { dataHelper } = this.maestro.helpers;
    const environment = options.host ? { host: options.host } : this._getEnvironment();
    let requestUrl = environment.host + options.path;

    options.headers = options.headers || {};
    options.headers['Content-Type'] = options.headers['Content-Type'] || 'application/json';

    // if (!options.headers['Authorization']) { need to send addr sigs
    //  options.headers['Authorization'] = options.genericAuth ? environment.authorizationKey : userManager?.store?.user?.accessToken;
    // }

    const requestOptions = {
      headers: options.headers,
      method: options.method,
      body: options.data,
    };

    if (options.headers['Content-Type'] === 'multipart/form-data') {
      delete requestOptions.headers['Content-Type'];
    }

    if (options.queryParams) {
      const queryString = Object.keys(options.queryParams).reduce((accumulator, paramName) => {
        const paramValue = (typeof options.queryParams[paramName] === 'object')
          ? JSON.stringify(options.queryParams[paramName])
          : options.queryParams[paramName];

        accumulator.push(`${encodeURIComponent(paramName)}=${encodeURIComponent(paramValue)}`);

        return accumulator;
      }, []).join('&');

      requestUrl = `${requestUrl}?${queryString}`;
    }

    const response = await fetch(requestUrl, requestOptions);

    return {
      code: response.status,
      body: (response.status !== 204) ? dataHelper.normalizeDataObject(await response.json()) : '',
    };
  }
}
