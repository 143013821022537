import { Component } from "react";
import maestro from "../maestro";
import { useNavigate } from "react-router";
import styles from "../styles/views/GrayboyShowcase.module.css";
import FancyDescription from "../components/basic/FancyDescription";
import GrayboysDisplay from "../components/GrayboysDisplay";
import Header from "../components/basic/Header";
import X from "../assets/images/X.svg";
import { isMobile } from 'react-device-detect';
import Incompatible from "./Incompatible";

const { apiHelper } = maestro.helpers;

class GrayboyShowcase extends Component {
  state = {
    currentId: 0,
    grayboys: []
  }

  async componentDidMount() {
    await this._loadMore();
  }

  _close = () => {
    this.props.navigate('../');
  }
  
  _renderHeader = () => {
    return (
      <Header>
        <FancyDescription 
          title="Select an NFT to mutate:"
          content="You can mutate each Gray Boy once per formula."
        />
        <img className={styles.removeImg} src={X} onClick={this._close}></img>
      </Header>
    )
  }

  _loadMore = async () => {
    const { currentId, grayboys } = this.state;
    const response = await apiHelper.get({
      path: `/grayboys/query?from=${currentId}&to=${currentId + 15}`,
    });
    this.setState({ currentId: currentId + 16, grayboys: grayboys.concat(response.body) });
  }

  _loadSpecific = async (event) => {
    const id = event.target.value;
    if (!id) {
      this.setState({ grayboys: [], currentId: 0 }, this._loadMore);
      return;
    }

    const response = await apiHelper.get({
      path: `/grayboys/query?from=${id}&to=${id}`,
    });
    this.setState({ grayboys: response.body });
  }

  _onKeyDown(event) {
    if(event.key != '-')
      return true;
    
    event.preventDefault();
    return false;
  }

  _render() {
    const { grayboys } = this.state;
    return (
      <div className={styles.container}>
        <FancyDescription 
          title="Gray Boys showroom"
          content="Gray Boys that have been mutated through one formula can still be mutated using another."
        />
        <div className={styles.inputContainer}>
          <div className={styles.inputTag}>#</div>
          <input className={styles.input} type="text" pattern="\d*" maxlength="4" onKeyDown={this._onKeyDown} onInput={this._loadSpecific}></input>
        </div>
        <div style={{marginTop:"1vh"}}></div>
        <GrayboysDisplay notSelectable={true} grayboys={grayboys} loadMore={this._loadMore}/>
      </div>
    )
  }

  render() {
    return (
      <>
        { !isMobile && this._render() }
        { isMobile && <Incompatible/> }
      </>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <GrayboyShowcase {...props} navigate={navigate} />
}
export default WithNavigate;