import { Component } from "react";
import Header from "../components/basic/Header";
import styles from "../styles/views/Quarantine.module.css";
import BackToLabButton from "../components/basic/BackToLabButton";
import MutantsDisplay from "../components/MutantsDisplay";
import ConnectedWallet from "../components/basic/ConnectedWallet";

class Quarantine extends Component {
  render() {
    return (
      <div className={styles.container}>
        <Header>
          <BackToLabButton/>
          <ConnectedWallet/>
        </Header>
        <MutantsDisplay/>
      </div>
    );
  }
}

export default Quarantine;