import { Component } from "react"
import maestro from '../../maestro';
import FollowTransaction from "./FollowTransaction";
import scientist from "../../assets/images/scientist.png";
import styles from "../../styles/components/modals/ConfirmMutate.module.css";

import { toast } from 'react-toastify';
import { serializeError } from 'eth-rpc-errors';
import Mutations from "../../consts/Mutations";
import Contracts from "../../consts/Contracts";

const { blockchainManager } = maestro.managers;

class ConfirmMutate extends Component {
  state = {
    disabled: false
  }

  componentDidMount() {
    maestro.link(this);
  }

  componentWillUnmount() {
    maestro.unlink(this);
  }

  receiveStoreUpdate({ blockchain }) {
    if (blockchain.address) {
      maestro.dispatchEvent('MODAL_HIDE');
    }
  }

  _approveIfNeeded = async (cost) => {
    const grayTokenContract = await blockchainManager.getGrayTokenContract();
    try {
      const balance = await grayTokenContract.balanceOf(blockchainManager.store.address);
      if(balance.lt(cost)) {
        toast.error("not enough $GRAY for mutation", {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
      }

      const allowance = await grayTokenContract.allowance(blockchainManager.store.address, Contracts.MUTATOR_CONTRACT_ADDRESS);
      if(allowance.lt(cost))
        return await this._approveGray();
      return true;
    }
    catch (error) {
      console.log(error);
      var parsedError = serializeError(error);
      toast.error(parsedError.data.originalError.reason, {
        position: "top-center",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      return false;
    }
  }

  _approveGray = async () => {
    const grayTokenContract = await blockchainManager.getGrayTokenContract();
    try {
      const tx = await grayTokenContract.approve(Contracts.MUTATOR_CONTRACT_ADDRESS, "1000000000000000000000000");
      var promise = tx.wait();
      toast.promise(promise, {
        pending: `Approving $GRAY`,
        success: `$GRAY approved`,
        error: 'Unexpected error'
     })
     await promise;
     return true;
    }
    catch (error) {
      console.error(error);
      var parsedError = serializeError(error);
      toast.error(parsedError.data.originalError.reason, {
        position: "top-center",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      return false;
    }
  }

  _mutate = async () => {
    this.setState({ disabled: true });

    const { currentMutation, grayboys } = this.props;
    const formula = Mutations.formulas[currentMutation];

    if(formula.grayTokenCost && formula.grayTokenCost > 0) {
      const success = await this._approveIfNeeded(formula.grayTokenCost);
      if(!success) {
        this.setState({ disabled: false });
        return;
      }
    }
    
    try {
      const tx = formula.requiresGB ? await formula.mutator(grayboys) : await formula.mutator(1);

      this._close();
      maestro.dispatchEvent('MODAL_SHOW', {
        disableClose: true,
        modal: (
          <FollowTransaction 
            transaction = { tx }
            txType = { formula.requiresGB ? "NORMAL" : "SPECIAL" }
          />
        )
      });
    }
    catch (error) {
      console.log(error);
      var parsedError = serializeError(error);
      toast.error(parsedError.data.originalError.reason, {
        position: "top-center",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    this.setState({ disabled: false });
  }

  _close = () => {
    maestro.dispatchEvent('MODAL_HIDE');
  }

  render() {
    const { disabled } = this.state;
    const { currentMutation, grayboys } = this.props;
    return (
      <div className={styles.container}>
        <img className={styles.scientistImg} src={scientist} alt="scientist"></img>
        <div className={styles.areYouSure}>Are you sure you want to mutate { 
          Mutations.formulas[currentMutation].requiresGB ? (grayboys.length > 1 ? `${grayboys.length} Gray Boys?` : `your Gray Boy #${grayboys[0].tokenID}?`) : "Ancient Crystal?" 
        }</div>
        <div className={styles.warning}>
          Burning your materials to mutate is a permanent action. 
          You will not be able to undo this action and the materials will no longer appear in your wallet.
        </div>
        <button className={styles.confirmButton} onClick={disabled ? null : this._mutate}>YES, I’M SURE</button>
        <button className={styles.cancelButton} onClick={this._close}>CANCEL MUTATION</button>
      </div>
    )
  }
}

export default ConfirmMutate;