import { Component } from "react";
import styles from "../styles/components/ToggleQuarantineButton.module.css";
import ConfirmQuarantine from "./modals/ConfirmQuarantine";
import maestro from "../maestro";
import ConfirmUnquarantine from "./modals/ConfirmUnquarantine";

class ToggleQuarantineButton extends Component {
  _quarantine = () => {
    const { mutant } = this.props;
    maestro.dispatchEvent('MODAL_SHOW', {
      filterStyle: styles.modalFilterStyle,
      modal: (<ConfirmQuarantine 
        mutant = { mutant }
      />)
    });
  }

  _unquarantine = () => {
    const { mutant } = this.props;
    maestro.dispatchEvent('MODAL_SHOW', {
      filterStyle: styles.modalFilterStyle,
      modal: (<ConfirmUnquarantine 
        mutant = { mutant }
      />)
    });
  }

  _renderQuarantine = () => {
    const { mutant } = this.props;
    return (
      <button className={`${styles.btn} ${styles.quarantineBtn}`} onClick={this._quarantine} disabled={true} style={{cursor: "not-allowed"}}>
        QUARANTINE #{mutant.tokenID}
      </button>
    )
  }

  _renderUnquarantine = () => {
    const { mutant } = this.props;
    return (
      <button className={`${styles.btn} ${styles.unquarantineBtn}`} onClick={this._unquarantine} disabled={true} style={{cursor: "not-allowed"}}>
        UNQUARANTINE #{mutant.tokenID}
      </button>
    )
  }

  render() {
    const { mutant } = this.props;
    return (
      <>
        { !mutant.isQuarantined && this._renderQuarantine() }
        { mutant.isQuarantined && this._renderUnquarantine() }
      </>
    )
  }
}
export default ToggleQuarantineButton;