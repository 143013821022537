import placeholder from "../assets/images/formula_spot.png";
import red_serum from "../assets/images/ingredients/red_serum.gif";
import blue_serum from "../assets/images/ingredients/blue_serum.gif";
import green_serum from "../assets/images/ingredients/green_serum.gif";
import ancient_crystal from "../assets/images/ingredients/crystal.gif";
import early_access_keycard from "../assets/images/ingredients/early_access_keycard.gif";
import styles from "../styles/components/LabItems.module.css";

class LabItems {
  static itemsMap = {
    0: {
      labId: null,
      isMintable: false,
      image: placeholder,
      borderColor: "#484848",
      name: "Placeholder",
      extraStyles: styles.placeholder
    },
    1: {
      labId: 1,
      isMintable: false,
      image: red_serum,
      name: "Red Serum",
      borderColor: "red",
      extraStyles: styles.redSerum
    },
    3: {
      labId: 3,
      isMintable: false,
      image: blue_serum,
      name: "Blue Serum",
      borderColor: "blue",
      extraStyles: styles.blueSerum
    },
    4: {
      labId: 4,
      isMintable: false,
      image: ancient_crystal,
      name: "Ancient Crystal",
      borderColor: "green",
      extraStyles: styles.ancientCrystal
    },
    5: {
      labId: 5,
      isMintable: false,
      image: green_serum,
      name: "Green Serum",
      borderColor: "green",
      extraStyles: styles.greenSerum
    },
    6: {
      labId: 6,
      isMintable: false,
      image: early_access_keycard,
      name: "Early Access Keycard",
      borderColor: "blue",
      extraStyles: styles.earlyAccessKeycard
    }/*,
    7: {
      labId: 7,
      name: "Embryo",
      isMintable: false,
      image: placeholder,
      borderColor: "purple",
      extraStyles: styles.embryo,
      maxMintsPerTransaction: 5,
      mintStyles: {
        container: styles.embryoMintContainer,
        confirmButton: styles.embryoMintButton,
        cancelButton: styles.embryoMintCancelButton,
        filterStyle: styles.embryoMintFilterStyle
      }
    }*/
  }
}
export default LabItems;