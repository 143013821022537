import { Component } from "react";
import "../../styles/fonts/FlyingSausage.css";
import "../../styles/animations/ThreeDots.css";
import styles from "../../styles/components/modals/FollowTransaction.module.css";
import { useNavigate } from "react-router-dom";
import maestro from "../../maestro";
import MutationCounter from "../basic/MutationCounter";
import Contracts from "../../consts/Contracts";

const { blockchainManager, grayboysManager, mutantsManager } = maestro.managers;

class FollowTransaction extends Component {
  async componentDidMount() {
    const { transaction } = this.props;
    const receipt = await transaction.wait();
    console.log("receipt:", receipt);
    await this.processNormalReceipt(receipt);
  }

  async processNormalReceipt(receipt) {
    const contract = blockchainManager.getFailedExperimentsContract();
    const logs = receipt.logs.filter(x => x.address === Contracts.FAILED_EXPERIMENTS_CONTRACT_ADDRESS);
    var smallestID = 9999999;
    var fromTokenIDs = []
    for(var i = 0; i < logs.length; i++) {
      var parsed = contract.interface.parseLog(logs[i]);
      var tokenId = parsed.args[2].toNumber();
      if(smallestID > tokenId)
        smallestID = tokenId;

      fromTokenIDs.push((tokenId % 10000).toString());
    }
    await this.pollForMutants(smallestID, fromTokenIDs);
  }

  async pollForMutants(firstTokenID, fromTokenIDs, currentMutants = []) {
    for(var i = currentMutants.length; i < fromTokenIDs.length; i++) {
      var mutant = await mutantsManager.getMutant(firstTokenID + i);
      if (!mutant || mutant.errorCode) {
        await new Promise(r => setTimeout(r, 1000));
        return await this.pollForMutants(firstTokenID, fromTokenIDs, currentMutants);
      }
      
      await mutantsManager.addMutant(mutant);
      currentMutants.push(mutant);
    }
    
    maestro.dispatchEvent('MODAL_HIDE');
    await grayboysManager._update();
    this.props.navigate('./display-minted', { 
      state: {
        grayboys: fromTokenIDs.map(x => x.toString()),
        mutants: currentMutants
      }
    });
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.mutatingText}>
          Mutating
          <div className="dot-flashing"></div>
        </div>
        <div className={styles.mutatingNote}>It is normal for this to take a minute. Do not refresh the browser.</div>
        <MutationCounter/>
      </div>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <FollowTransaction {...props} navigate={navigate} />
}
export default WithNavigate;