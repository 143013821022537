import { ethers } from "ethers";
import maestro from "../maestro";

class Mutations {
  static formulas = {
    0: { //L1
      name: "LEVEL 1 FAILED EXPERIMENT",
      requiresGB: true,
      grayTokenCost: ethers.utils.parseEther("0"),
      mutator: async (grayboys) => {
        const { mutatorHelper } = maestro.helpers;
        return await mutatorHelper.mutateL1(grayboys);
      },
      costs: {
        1: 1, //1 red serum
        5: 1, //2 green serum
        //6: 1 //1 access keycard
      }
    },
    1: { //L2
      name: "LEVEL 2 FAILED EXPERIMENT",
      requiresGB: true,
      grayTokenCost: ethers.utils.parseEther("0"),
      mutator: async (grayboys) => {
        const { mutatorHelper } = maestro.helpers;
        return await mutatorHelper.mutateL2(grayboys);
      },
      costs: {
        1: 2, //1 red serum
        5: 2, //2 green serum
        //6: 1 //1 access keycard
      }
    },
    2: { //ABOMINATION / CRYSTAL 1 OF 1
      name: "ABOMINATION",
      requiresGB: false,
      grayTokenCost: ethers.utils.parseEther("0"),
      mutator: async (count) => {
        const { mutatorHelper } = maestro.helpers;
        return await mutatorHelper.mutateCrystal(count);
      },
      costs: {
        4: 1, //1 ancient crystal
      }
    }
  }
}
export default Mutations;