import { Component } from "react";
import styles from "../../styles/components/basic/Header.module.css";

class Header extends Component {
  render() {
    const { extraStyles } = this.props;
    return (
      <div className={`${styles.container} ${extraStyles}`}>
        {this.props.children}
      </div>
    )
  }
}

export default Header;