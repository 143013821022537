import { Component } from "react";
import maestro from "../../maestro";
import styles from "../../styles/components/modals/ConfirmUnquarantine.module.css";
import MutantImage from "../MutantImage";
import { toast } from 'react-toastify';
import { serializeError } from 'eth-rpc-errors';

const { quarantineManager } = maestro.managers;

class ConfirmUnquarantine extends Component {
  _unquarantine = async () => {
    const { mutant } = this.props;
    try {
      const tx = await quarantineManager.unquarantine(1, [mutant]);
      this._close();
      var promise = tx.wait();
      toast.promise(promise, {
        pending: `Releasing mutant #${mutant.tokenID}`,
        success: `Mutant #${mutant.tokenID} released`,
        error: 'Unexpected error'
     })
     await promise;
     mutant.isQuarantined = false;
     maestro.dispatchEvent('MUTANT_UNQUARANTINED');
    }
    catch (error) {
      console.error(error);
      var parsedError = serializeError(error);
      toast.error(parsedError.data.originalError.reason, {
        position: "top-center",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  _close = () => {
    maestro.dispatchEvent('MODAL_HIDE');
  }

  render() {
    const { mutant } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.centerContainer}>
          <div className={styles.imgContainer}>
            <MutantImage drawBars={false} mutant={mutant}/>
          </div>
        </div>
        <div className={styles.areYouSure}>Are you sure you want to release #{mutant.tokenID} from quarantine?</div>
        <div className={styles.warning}>
          Unquarantining sends the mutant back to your wallet. Make sure to claim your rewards first!
        </div>
        <button className={styles.confirmButton} onClick={this._unquarantine}>YES, RELEASE</button>
        <div className={styles.centerContainer}>
          <button className={styles.cancelButton} onClick={this._close}>CANCEL</button>
        </div>
      </div>
    )
  }
}

export default ConfirmUnquarantine;