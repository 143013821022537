import { Component } from "react";
import maestro from "../../maestro";
import styles from "../../styles/components/bench/Bench.module.css";
import IngredientSpot from "./IngredientSpot";

const { benchManager } = maestro.managers;

class Bench extends Component {
  state = {
    ingredients: [0, 0, 0, 0, 0, 0, 0, 0, 0]
  }

  componentDidMount() {
    maestro.link(this);
  }

  componentWillUnmount() {
    maestro.unlink(this);
  }

  receiveStoreUpdate({ bench, grayboys }) {
    this.setState({ ingredients: bench.ingredients, selectedGrayboys: grayboys.selectedGrayboys });
  }

  _renderSlots() {
    const { selectedGrayboys, ingredients } = this.state;
    var ret = [];
    for(var i = 0; i < ingredients.length; i++) {
      ret.push(
        <IngredientSpot 
          slotId={i} 
          ingredientId = {ingredients[i]}
          amount = { selectedGrayboys ? selectedGrayboys.length : 0 }
          key={`bench_slot_${i}`}
        />
      );
    }
    return ret;
  }
  
  render() {
    return (
      <div className={styles.container}>
        { this._renderSlots() }
      </div>
    )
  }
}

export default Bench;