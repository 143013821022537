import { Component } from "react";
import styles from "../../styles/components/shop/LabItemsDisplay.module.css";
import LabItem from "./LabItem";
import Divider from "../basic/Divider";
import maestro from "../../maestro";

class LabItemsDisplay extends Component {
  state = {
    amounts: {}
  }

  componentDidMount() {
    maestro.link(this);
  }

  componentWillUnmount() {
    maestro.unlink(this);
  }

  receiveStoreUpdate({ inventory }) {
    const { amounts } = this.state;
    if(inventory && inventory.amounts != amounts) {
      this.setState({ amounts: inventory.amounts });
    }
  }

  _renderItem = (id) => {
    const { amounts } = this.state;
    return (
      <LabItem itemId={id} amount={amounts[id]}/>
    )
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.info}>
            <Divider extraStyles={styles.semiDividerLeft}/>
            <div className={styles.text}>ADD A SERUM:</div>
            <Divider extraStyles={styles.semiDividerRight}/>
          </div>
          <div className={styles.items}>
            { this._renderItem(1) }
            { this._renderItem(3) }
            { this._renderItem(5) }
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.info}>
            <Divider extraStyles={styles.semiDividerLeft}/>
            <div className={styles.text}>ADD A SPECIAL:</div>
            <Divider extraStyles={styles.semiDividerRight}/>
          </div>
          <div className={styles.items}>
            { this._renderItem(4) }
            { this._renderItem(6) }
          </div>
        </div>
      </div>
    )
  }
}
export default LabItemsDisplay;