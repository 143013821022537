import { createRef, Component } from "react";
import styles from "../styles/components/GrayboysDisplay.module.css"
import Grayboy from "./Grayboy";

class GrayboysDisplay extends Component {
  state = {
    triggered: false
  }
  constructor(props) {
    super(props);
    this.loaderRef = createRef();
  }

  _handleScroll = (test) => {
    const { loadMore } = this.props;
    const { triggered } = this.state;

    const top = this.loaderRef.current.getBoundingClientRect().top;
    const isVisible = (top) >= 0 && (top) <= window.innerHeight;
    if(!triggered && isVisible) {
      loadMore();
      this.setState({ triggered: true });
    }

    if(triggered && !isVisible) {
      this.setState({ triggered: false });
    }
  }

  _renderSpacers = () => {
    const { grayboys } = this.props;
    const pending = (grayboys.length % 4);
    var ret = []
    for(var i = 0; i < pending; i++) {
      ret.push(
        <Grayboy
          grayboy = {grayboys[0]}
          key = {`spacer_${i}`}
          spacer = { true }
          style = {{pointerEvents: 'none !important'}}
        />
      )
    }
    return ret;
  }

  render() {
    const { grayboys, notSelectable, loadMore } = this.props;
    return (
      <>
        <div className={styles.container} onScroll={loadMore ? this._handleScroll : null}>
          { 
            grayboys.map((grayboy, index) => (
              <div 
                className={styles.containerItem}
                key = {`quarantined_mutant_container_${index}`}
              >
                <Grayboy
                  grayboy = {grayboy}
                  notSelectable = { notSelectable }
                  key = {`grayboy_${grayboy.tokenID}`}
                />
              </div>
            ))
          }
          {
            this._renderSpacers()
          }
          <div ref={this.loaderRef} style={{width: '100%'}}></div>
        </div>
      </>
    )
  }
}

export default GrayboysDisplay;