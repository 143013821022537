import { Component } from "react"
import Header from "../components/basic/Header";
import Logo from "../components/basic/Logo";
import styles from "../styles/views/DisplayMinted.module.css";
import OpenSeaButton from "../components/basic/OpenSeaButton";
import FancyDescription from "../components/basic/FancyDescription";
import ViewOnOpenSeaButton from "../components/ViewOnOpenSeaButton";
import QuarantineButton from "../components/basic/QuarantineButton";
import ConfirmQuarantine from "../components/modals/ConfirmQuarantine";
import { useLocation, useNavigate } from "react-router-dom";
import Tank from "../components/Tank";
import maestro from "../maestro";
import Mutations from "../consts/Mutations";
import arrow from "../assets/images/arrow.png";

class DisplayMinted extends Component {
  state = {
    mutants: this.props.location.state.mutants,
    grayboys: this.props.location.state.grayboys,
    currentId: 0
  }

  _mutateAnother = () => {
    this.props.navigate('../');
  }

  _displayNext = () => {
    this.props.navigate('.');
  }

  _quarantineNow = () => {
    const { currentId, mutants } = this.state;
    maestro.dispatchEvent('MODAL_SHOW', {
      filterStyle: styles.modalFilterStyle,
      modal: (<ConfirmQuarantine 
        mutant = { mutants[currentId] }
      />)
    });
  }

  _getTitle() {
    const { currentId, grayboys, mutants } = this.state;
    return (
      <div className={styles.successMsg}>
        <div>{`${grayboys ? "Gray Boy #" + grayboys[currentId] : "Ancient Crystal"} has mutated into...`}</div>
        <div className={styles.lowerMsg}>{Mutations.formulas[mutants[currentId].typeID].name + ` #${mutants[currentId].tokenID}`}</div>
      </div>
    )
  }

  _offset = (offset) => {
    const { currentId, mutants } = this.state;
    if(currentId + offset >= mutants.length)
      this.setState({ currentId: 0 });
    else if(currentId + offset < 0)
      this.setState({ currentId: mutants.length - 1 });
    else
      this.setState({ currentId: currentId + offset });
  }

  render() {
    const { currentId, grayboys, mutants } = this.state;
    return (
      <div className={styles.container}>
        <div className="top">
          <Header>
            <Logo/>
            <div className={styles.topActions}>
              <QuarantineButton uri="../quarantine"/>
              <OpenSeaButton/>
            </div>
          </Header>
          <FancyDescription title = {this._getTitle()}/>
          <ViewOnOpenSeaButton tokenId={mutants[currentId].tokenID}/>
        </div>
        <div className={styles.middle}>
          {
            mutants.length > 1 &&
            <div className={styles.switchersContainer}>
              <img className={styles.selector} src={arrow} onClick={() => this._offset(-1)}></img>
              <img className={styles.selector} src={arrow} style={{transform: "rotate(180deg)"}} onClick={() => this._offset(+1)}></img>
            </div>
          }
          <Tank subject={mutants[currentId].imageURL}/>
        </div>
        <div className={styles.bottom}>
          <div className={styles.row}>
            <button className={styles.smallBtn} onClick={this._quarantineNow} disabled={true} style={{cursor: "not-allowed"}}>QUARANTINE NOW</button>
            <button className={`${styles.smallBtn} ${styles.smallBtnRight}`} onClick={this._mutateAnother}>MUTATE ANOTHER</button>
          </div>
          <div className={styles.row}>
            { 
              grayboys && 
              <a className={styles.bigBtn} href={"https://twitter.com/intent/tweet?text=" +
                `My%20Gray%20Boy%20has%20mutated%20into%20a%20horrific%20version%20of%20itself%21%0AGray%20Boy%20%23${grayboys[currentId]}%20became%20a%20%22${Mutations.formulas[mutants[currentId].typeID].name}%22%3A%20%23${mutants[currentId].tokenID}%21%0Ahttps://opensea.io/assets/ethereum/0xf4539901ea3f319ef92888413796b59b26ae4c4a/${mutants[currentId].tokenID}`
              }>
                SHARE YOUR MUTANT
                <div className={styles.smallText}>
                  ON TWITTER
                </div>
              </a>
            }
            { 
              !grayboys && 
              <a className={styles.bigBtn} href={"https://twitter.com/intent/tweet?text=" +
                `My%20Ancient Crystal%20has%20mutated%20into%20a%20horrific%20version%20of%20itself%21%0AAncient Crystal%20became%20a%20%22${Mutations.formulas[mutants[currentId].typeID].name}%22%3A%20%23${mutants[currentId].tokenID}%21%0Ahttps://opensea.io/assets/ethereum/0xf4539901ea3f319ef92888413796b59b26ae4c4a/${mutants[currentId].tokenID}`
              }>
                SHARE YOUR MUTANT
                <div className={styles.smallText}>
                  ON TWITTER
                </div>
              </a>
            }
          </div>
        </div>
      </div>
    );
  }
}

function WithHooks(props) {
  let location = useLocation();
  let navigate = useNavigate();
  return <DisplayMinted {...props} location={location} navigate={navigate} />
}
export default WithHooks;