import { Manager } from 'react-native-maestro';

export default class MutantsManager extends Manager {
  /* Maestro required fields */
  static get instanceKey() {
    return 'mutantsManager';
  }

  static initialStore = {
    owned: []
  }

  get storeName() {
    return 'mutants';
  }
  /* Finish Maestro fields */

  receiveStoreUpdate({ blockchain }) {
    const { address } = this.store;
    if (blockchain && blockchain.address && address !== blockchain.address) {
      this.updateStore({ address: blockchain.address });
      this._update();
    }
  }

  getMutant = async tokenId => {
    const { apiHelper } = this.maestro.helpers;
    const stakedWorldsResult = await apiHelper.get({
      path: `/mutants/1/${tokenId}`,
    });
    return stakedWorldsResult.body;
  }

  addMutant = mutant => {
    const { owned } = this.store;
    owned.push(mutant);
    this.updateStore({ owned: owned });
  }

  _getMutants = async address => {
    const { apiHelper } = this.maestro.helpers;
    const stakedWorldsResult = await apiHelper.get({
      path: `/mutants/owned/${address}`,
    });
    return stakedWorldsResult.body;
  }

  _update = async () => {
    const { blockchainManager } = this.maestro.managers;
    const { address } = blockchainManager.store;
    var mutants = await this._getMutants(address);
    this.updateStore({ address: address, owned: mutants });
  }
}
