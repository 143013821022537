import { Component } from "react";
import styles from "../../styles/components/basic/FancyDescription.module.css";

class FancyDescription extends Component {
  render() {
    const { title, content } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.title}>{title}</div>
        <div className={styles.content}>{content}</div>
      </div>
    )
  }
}

export default FancyDescription;