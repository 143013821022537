import { Component } from "react";
import styles from "../styles/components/DistantTank.module.css";
import tank from "../assets/images/tank_masked.svg";
import arrow from "../assets/images/arrow.png";

import maestro from "../maestro";
class DistantTank extends Component {
  state = {
    selectedGrayboys: [],
    selectedId: null
  }

  componentDidMount() {
    maestro.link(this);
  }

  componentWillUnmount() {
    maestro.unlink(this);
  }

  receiveStoreUpdate({ grayboys }) {
    const { selectedGrayboys } = this.state;
    if(grayboys && grayboys.selectedGrayboys != selectedGrayboys) {
      this.setState({ selectedGrayboys: grayboys.selectedGrayboys, selectedId: grayboys.selectedGrayboys.length > 0 ? 0 : null });
    }
  }

  _offset = (offset) => {
    const { selectedId, selectedGrayboys } = this.state;
    if(selectedId == null) return;

    if(selectedId + offset >= selectedGrayboys.length)
      this.setState({ selectedId: 0 });
    else if(selectedId + offset < 0)
      this.setState({ selectedId: selectedGrayboys.length - 1 });
    else
      this.setState({ selectedId: selectedId + offset });
  }

  render() {
    const { selectedGrayboys, selectedId } = this.state;
    return (
      <div className={styles.container}>
        { selectedGrayboys && selectedGrayboys.length > 1 &&
          <div className={styles.switchersContainer}>
            <img className={styles.selector} src={arrow} onClick={() => this._offset(-1)}></img>
            <img className={styles.selector} src={arrow} style={{transform: "rotate(180deg)"}} onClick={() => this._offset(+1)}></img>
          </div>
        }
        <div className={styles.testSubjectContainer}>
          <div className={styles.testSubjectMask}>
            { selectedId != null && (<img src={selectedGrayboys[selectedId].imageURL} className={styles.testSubject} alt=""/>) }
          </div>
        </div>
        <img src={tank} className={styles.tank} alt=""/>
      </div>
    )
  }
}
export default DistantTank;