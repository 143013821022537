import { Component } from "react";
import maestro from "../maestro";
import Logo from "../components/basic/Logo";
import Modals from "../components/modals/Modals";
import styles from "../styles/views/Initial.module.css";
import BigConnectButton from "../components/BigConnectButton";
import scienceLabLogo from "../assets/images/science_lab_logo_vertical.svg";

import { useNavigate } from "react-router";
import { ToastContainer } from "react-toastify";
import { isMobile } from 'react-device-detect';
import Incompatible from "./Incompatible";

const { blockchainManager } = maestro.managers;

class Initial extends Component {
  state = {
    address: null,
    network: null
  }

  componentDidMount() {
    maestro.link(this);
  }

  componentWillUnmount() {
    maestro.unlink(this);
  }

  receiveStoreUpdate({ blockchain }) {
    const { network } = this.state;
    if(blockchain.network && blockchain.network != network) {
      this.setState({ network: blockchain.network });
      if(blockchain.network != 'homestead')
        blockchainManager.switchWalletToEthereumNetwork();
    }

    if(blockchain.address && blockchain.address.length > 10 && blockchain.network == 'homestead')
      this.props.navigate('../main');
  }

  _render() {
    return (
      <>
        <ToastContainer
          theme="colored"
          position="top-right"
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <div className={styles.container}>
          <Modals/>
          <div></div>
          <div className={styles.mainPage}>
            <div className={styles.top}>
              <Logo extraStyles={styles.logo}/>
            </div>
            <div className={styles.middle}>
              <img src={scienceLabLogo} className={styles.scienceLabLogo}/>
              <div className={styles.mainText}>Connect your wallet to enter the Science Lab.</div>
              <div className={styles.minorText}>The Science Lab is where you will mutate your Gray Boy; click on serums or specials to add them to the mixing bench; find the correct formula, and mutate. Mutations temporarily do not cost any $GRAY.</div>
            </div>
            <div className={styles.bottom}>
              <BigConnectButton/>
            </div>
          </div>
          <div></div>
        </div>
      </>
    )
  }

  render() {
    return (
      <>
        { !isMobile && this._render() }
        { isMobile && <Incompatible/> }
      </>
    )
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <Initial {...props} navigate={navigate} />
}
export default WithNavigate;