import { Manager } from 'react-native-maestro';

export default class ShopManager extends Manager {
  /* Maestro required fields */
  static get instanceKey() {
    return 'shopManager';
  }

  static initialStore = {
    owned: []
  }

  get storeName() {
    return 'shop';
  }
  /* Finish Maestro fields */

  mintItem = async (itemId, amount) => {
    const { blockchainManager } = this.maestro.managers;
    const shopContract = await blockchainManager.getShopContract();
    return await shopContract.mint(itemId, amount);
  }
}
