import { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./styles/Global.css";
import Main from "./views/Main"
import Initial from "./views/Initial";
import Quarantine from "./views/Quarantine";
import DisplayMinted from "./views/DisplayMinted";
import GrayboySelection from "./views/GrayboySelection";
import GrayboyShowcase from "./views/GrayboyShowcase";
import Incompatible from "./views/Incompatible";

/* Main is always loaded in order to retain state */
class Router extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Initial/>}/>
            <Route path="/Incompatible" element={<Incompatible/>}/>
            <Route path="/Main" element={<Main/>}>
              <Route path="display-minted" element={<DisplayMinted/>}/>
              <Route path="quarantine" element={<Quarantine/>}/>
              <Route path="select-gb" element={<GrayboySelection/>}/>
            </Route>
            <Route exact path="/Showcase" element={<GrayboyShowcase/>}/>
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default Router;