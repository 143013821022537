import { Component } from "react";
import styles from "../../styles/components/basic/MutationCounter.module.css";
import maestro from "../../maestro";

const { blockchainManager } = maestro.managers;

class MutationCounter extends Component {
  state = {
    count: 0
  }

  componentDidMount() {
    //this._update();
    //this.interval = setInterval(() => this._update(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  _update = async () => {
    const failedExperimentsContract = await blockchainManager.getFailedExperimentsContract();
    const count = await failedExperimentsContract.totalSupply();
    this.setState({ count: count.toString() });
  }

  receiveStoreUpdate({ grayboys }) {
    if(grayboys.owned && grayboys.owned != this.state.grayboys) {
      this.setState({ grayboys: grayboys.owned });
    }
  }

  render() {
    const { count } = this.state;
    return (
      <div className={styles.container}>
        Total mutations so far: {count}
      </div>
    )
  }
}

export default MutationCounter;