import { Component } from "react";
import quarantine_icon from "../assets/images/quarantine_icon.svg";
import styles from "../styles/components/DaysInQuarantine.module.css";
import ToggleQuarantineButton from "./ToggleQuarantineButton";

class DaysInQuarantine extends Component {
  render() {
    const { days } = this.props;
    return (
      <div className={styles.container}>
        <img className={styles.icon} src={quarantine_icon}></img>
        Days in Quarantine: {days}
      </div>
    )
  }
}
export default DaysInQuarantine;