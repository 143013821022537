import { Component } from "react";
import Logo from "../components/basic/Logo";
import styles from "../styles/views/Incompatible.module.css";

class Incompatible extends Component {
  render() {
    return (
      <div className={styles.container}>
        <Logo extraStyles={styles.logo}/>
        <div>This system is currently only available for desktop devices.</div>
      </div>
    );
  }
}

export default Incompatible;