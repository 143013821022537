import { Manager } from 'react-native-maestro';

export default class GrayboysManager extends Manager {
  /* Maestro required fields */
  static get instanceKey() {
    return 'grayboysManager';
  }

  static initialStore = {
    selectedGrayboys: [],
    owned: [],
    cachedImages: []
  }

  get storeName() {
    return 'grayboys';
  }
  /* Finish Maestro fields */

  receiveStoreUpdate({ blockchain }) {
    const { address } = this.store;
    if (blockchain && blockchain.address && address !== blockchain.address) {
      this.updateStore({ address: blockchain.address });
      this._update();
    }
  }

  toggleGrayboy = (grayboy) => {
    const { owned } = this.store;
    grayboy.isSelected = !grayboy.isSelected;
    var selectedGrayboys = owned.filter(x => x.isSelected);
    this.updateStore({ selectedGrayboys: selectedGrayboys });
  }

  _getGrayboys = async address => {
    const { apiHelper } = this.maestro.helpers;
    const stakedWorldsResult = await apiHelper.get({
      path: `/grayboys/${address}`,
    });
    return stakedWorldsResult.body;
  }

  _update = async () => {
    const { blockchainManager } = this.maestro.managers;
    const { address } = blockchainManager.store;
    const grayboys = await this._getGrayboys(address);

    /* Lazy load all grayboys images - this is kinda dumb but does the job */
    var cachedImages = []
    grayboys.forEach(grayboy => {
      const image = new Image();
      image.src = grayboy.imageURL;
      cachedImages.push(image);
    });
    this.updateStore({ owned: grayboys, cachedImages: cachedImages });
  }
}
