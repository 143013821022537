import { Component } from "react";
import maestro from "../../maestro";
import styles from "../../styles/components/modals/ConfirmQuarantine.module.css";
import MutantImage from "../MutantImage";
import { toast } from 'react-toastify';
import { serializeError } from 'eth-rpc-errors';

const { quarantineManager } = maestro.managers;

class ConfirmQuarantine extends Component {
  _quarantine = async () => {
    const { mutant } = this.props;
    try {
      const tx = await quarantineManager.quarantine(1, [mutant]);
      this._close();
      var promise = tx.wait();
      toast.promise(promise, {
        pending: `Quarantining mutant #${mutant.tokenID}`,
        success: `Mutant #${mutant.tokenID} quarantined`,
        error: 'Unexpected error'
     })
     await promise;
     mutant.isQuarantined = true;
     maestro.dispatchEvent('MUTANT_QUARANTINED');
    }
    catch (error) {
      console.error(error);
      var parsedError = serializeError(error);
      toast.error(parsedError.data.originalError.reason, {
        position: "top-center",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  _close = () => {
    maestro.dispatchEvent('MODAL_HIDE');
  }

  render() {
    const { mutant } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.centerContainer}>
          <div className={styles.imgContainer}>
            <MutantImage drawBars={true} mutant={mutant}/>
          </div>
        </div>
        <div className={styles.areYouSure}>Are you sure you want to place #{mutant.tokenID} in quarantine?</div>
        <div className={styles.warning}>
          Quarantining this item will make it disappear from your wallet. You can unquarantine at any time, and will receive $GRAY as a reward.
        </div>
        <button className={styles.confirmButton} onClick={this._quarantine}>YES, QUARANTINE</button>
        <div className={styles.centerContainer}>
          <button className={styles.cancelButton} onClick={this._close}>CANCEL</button>
        </div>
      </div>
    )
  }
}

export default ConfirmQuarantine;