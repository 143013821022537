import { Component } from "react";
import maestro from "../../maestro";
import styles from "../../styles/components/modals/ConfirmClaim.module.css";
import grayToken from "../../assets/images/Coin.svg";
import { toast } from 'react-toastify';
import { serializeError } from 'eth-rpc-errors';

const { quarantineManager } = maestro.managers;

class ConfirmClaim extends Component {
  _claim = async () => {
    try {
      const tx = await quarantineManager.claimRewards();
      this._close();
      toast.promise(tx.wait(), {
        pending: `Claiming $GRAY rewards`,
        success: `$GRAY rewards claimed`,
        error: 'Unexpected error'
     })
    }
    catch (error) {
      console.error(error);
      var parsedError = serializeError(error);
      toast.error(parsedError.data.originalError.reason, {
        position: "top-center",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  _close = () => {
    maestro.dispatchEvent('MODAL_HIDE');
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.centerContainer}>
          <img className={styles.tokenImg} src={grayToken}></img>
        </div>
        <div className={styles.areYouSure}>Are you sure you want to claim your $GRAY?</div>
        <div className={styles.warning}>
          Doing this action will reset your claimable rewards counter. Claimed tokens are available for trading or usage instantly.
        </div>
        <button className={styles.confirmButton} onClick={this._claim}>YES, CLAIM</button>
        <div className={styles.centerContainer}>
          <button className={styles.cancelButton} onClick={this._close}>CANCEL</button>
        </div>
      </div>
    )
  }
}

export default ConfirmClaim;